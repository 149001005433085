import { AdminsService } from '../../services/admins.service.js';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    showDialog() {
      this.selectedType = 'Doctor';
      this.notes = '';
      this.alert.show = false;
      this.buttonDisabled = false;
    },
    selectedType(type) {
      this.selectedIssue = this.issues[type][0];
    }
  },
  data() {
    return {
      alert: {
        show: false,
        message: ''
      },
      showDialog: false,
      categories: ['Doctor', 'Package', 'Patient', 'Pharmacy'],
      selectedType: 'Doctor',
      selectedIssue: 'Prescribing issue',
      notes: '',
      issues: {
        Doctor: ['Prescribing issue', 'Unresponsive to Patient', 'Unresponsive to PS'],
        Package: ['Shipping Delay - Pharmacy', 'Shipping Delay - Carrier', 'Product Damaged', 'Product Never Arrived'],
        Patient: ['Reported Fraudulent Charges', 'Aggressive Behavior', 'Risk of Offboarding'],
        Pharmacy: ['Incorrect Medication Sent', 'Medication Not Sent', 'Shipping Delay']
      },
      buttonDisabled: false
    };
  },
  methods: {
    async submitReport() {
      if (!this.notes) {
        this.alert.message = 'Please fill in all the fields.';
        this.alert.show = true;
        return;
      }
      const body = {
        note: this.notes,
        type: this.selectedType,
        issue: this.selectedIssue
      };
      try {
        this.buttonDisabled = true;
        const {
          data
        } = await AdminsService.reportIssue(this.rowData.id, body);
        if (data) {
          this.showDialog = false;
        }
      } catch (err) {
        this.alert.message = err;
        this.alert.show = true;
      } finally {
        this.buttonDisabled = false;
      }
    }
  }
};